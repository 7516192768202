.edit-card {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  box-shadow: -6px -6px 14px rgba(var(--shadow), 0.7), -6px -6px 10px rgba(var(--shadow), 0.5),
    6px 6px 8px rgba(var(--shadow), 0.075), 6px 6px 10px rgba(var(--shadowBlack), 0.15);
}

.man-img {
  width: 100%;
  height: 100%;
  border-radius: 40px;
  object-fit: cover;
}

.content-container {
  box-shadow: -6px -6px 14px rgba(var(--shadow), 0.7), -6px -6px 10px rgba(var(--shadow), 0.5),
    6px 6px 8px rgba(var(--shadow), 0.075), 6px 6px 10px rgba(var(--shadowBlack), 0.15);
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px 5px;
  position: relative;
  border-radius: 10px;
}

.overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #000000;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: 0.5s ease;
  opacity: 1;
  font-size: 20px;
  padding: 30px;
  color: var(--backgroundMain);
  text-align: center;
}

.overlay:hover {
  opacity: 1;
  cursor: pointer;
}

.content-container:hover {
  box-shadow: -2px -2px 6px rgba(var(--shadow), 0.6), -2px -2px 4px rgba(var(--shadow), 0.4),
    2px 2px 2px rgba(var(--shadow), 0.05), 2px 2px 4px rgba(var(--shadowBlack), 0.1);
}

.img-box {
  width: 65px;
  height: 65px;
  overflow: hidden;
}

.text-area {
  padding: 12px;
  border-radius: 8px;
  border: none;
  box-shadow: inset 6px 6px 10px 0 rgba(var(--shadowBlack), 0.1),
    inset -6px -6px 10px 0 rgba(var(--shadow), 0.5);
}

.h-fit-content {
  height: fit-content;
}

[name='test'] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
[name='test'] + div {
  cursor: pointer;
}

/* CHECKED STYLES */
[name='test']:checked + div {
  outline: 2px solid var(--primary);
}
