.login-card {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  box-shadow: -6px -6px 14px rgba(var(--shadow), 0.7), -6px -6px 10px rgba(var(--shadow), 0.5),
    6px 6px 8px rgba(var(--shadow), 0.075), 6px 6px 10px rgba(var(--shadowBlack), 0.15);
}
