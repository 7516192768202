.cancel-button {
  position: absolute;
  right: 4%;
  top: 8%;
}

.upload-file {
  border: 1px dashed gainsboro;
  background-color: white;
  border-radius: 12px;
  text-align: center;
  padding: 30px;
  width: 100%;
  cursor: pointer;
}
