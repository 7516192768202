.call-details-table-container {
  border-radius: 12px;
  box-shadow: -6px -6px 14px rgba(var(--shadow), 0.7), -6px -6px 10px rgba(var(--shadow), 0.5),
    6px 6px 8px rgba(var(--shadow), 0.075), 6px 6px 10px rgba(var(--shadowBlack), 0.15);
}

.drop-toggle {
  box-shadow: -6px -6px 14px rgba(var(--shadow), 0.7), -6px -6px 10px rgba(var(--shadow), 0.5),
    6px 6px 8px rgba(var(--shadow), 0.075), 6px 6px 10px rgba(var(--shadowBlack), 0.15);
  padding: 0.5rem 1rem;
  font-weight: 500;
  border-radius: 8px;
  min-height: 48px;
}

.drop-toggle:hover {
  box-shadow: -2px -2px 6px rgba(var(--shadow), 0.6), -2px -2px 4px rgba(var(--shadow), 0.4),
    2px 2px 2px rgba(var(--shadow), 0.05), 2px 2px 4px rgba(var(--shadowBlack), 0.1);
}

.table-container {
  overflow: auto;
}

table {
  width: 100%;
}

thead {
  background-color: #dad8e4;
  color: #042b66;
  height: 40px;
}

th {
  font-weight: normal;
}

tr {
  height: 40px;
}

.active-page {
  background-color: var(--primary);
  color: var(--white);
}

.call-row:hover {
  background-color: #f7f6ff;
  color: var(--primary);
  border-bottom: 1px solid rgb(219, 219, 219);
}

.pagination {
  background-color: #dad8e4;
  border-radius: 50px;
}

.page-item,
.previous,
.next,
.break {
  width: 5vw;
  height: 5vw;
  max-width: 30px;
  max-height: 30px;
  min-width: 20px;
  min-height: 20px;
  border: none;
  border-radius: 50px;
  background-color: #dad8e4;
  padding: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled a {
  color: gray;
}

@media only screen and (max-width: 660px) {
  .sidebar-size {
    overflow-wrap: initial;
  }
}

.sidebar-size {
  width: 660px;
  /* background-color: red; */
  /* position: fixed; */
}

.overlay-99 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  /* opacity: 0.5; */
}

.shows {
  opacity: 0.5;
}

.faded {
  opacity: 0;
}
