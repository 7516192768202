.campaign-card {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
  box-shadow: -6px -6px 14px rgba(var(--shadow), 0.7), -6px -6px 10px rgba(var(--shadow), 0.5),
    6px 6px 8px rgba(var(--shadow), 0.075), 6px 6px 10px rgba(var(--shadowBlack), 0.15);
}

.card-tag {
  padding: 2px;
  background-color: gray;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
}

.pill {
  padding: 1px 9px;
  border-radius: 17px;
  font-weight: bold;
  font-size: 12px;
  width: fit-content;
}

.outbound-pill {
  background-color: var(--lightGreen);
  color: var(--green);
}

.inbound-pill {
  background-color: var(--lightRed);
  color: var(--red);
}

.transcription-text {
  color: var(--blue);
  text-decoration: underline;
  cursor: pointer;
}
