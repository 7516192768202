* {
  font-family: 'Inter', sans-serif;
}

body {
  background-color: var(--backgroundMain);
  overflow-y: scroll !important;
}

html {
  font-size: 16px;
}
/* font-size: 24px; line-height: 48px */
.heading-1 {
  font-size: 2rem;
  font-weight: 800;
  line-height: 3rem;
  margin-bottom: 0px;
}

/* font-size: 22px; line-height: 32px */
.heading-2 {
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 2rem;
  margin-bottom: 0px;
}

/* font-size: 22px; line-height: 32px */
.heading-3 {
  font-size: 0.875rem;
  line-height: 1.4;
  margin-bottom: 0px;
}

/* font-size: 16px; line-height: 24px */
.para {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0px;
}
/* font-size: 14px; line-height: 20px */
.caption {
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0px;
}

.font-color-gray {
  color: var(--textSecondary) !important;
}

.dark-text {
  color: var(--darkText) !important;
}

.white-text {
  color: var(--white);
}

.secondary-color {
  color: var(--primary);
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

@media only screen and (max-width: 350px) {
  html {
    font-size: 14px;
  }
}
