.btn {
  min-height: 44px;
  font-weight: 500;
  border-radius: 8px;
  padding: 0.5rem 1rem;
}

.primary {
  background-color: var(--primary);
  color: var(--white);
  transition: 1s;
  border-color: var(--primary);
  box-shadow: -6px -6px 14px rgba(var(--shadow), 0.7), -6px -6px 10px rgba(var(--shadow), 0.5),
    6px 6px 8px rgba(var(--shadow), 0.075), 6px 6px 10px rgba(var(--shadowBlack), 0.15);
}

.btn-link {
  text-decoration: none;
  color: var(--primary);
}

.primary:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--white);
  box-shadow: -2px -2px 6px rgba(var(--shadow), 0.6), -2px -2px 4px rgba(var(--shadow), 0.4),
    2px 2px 2px rgba(var(--shadow), 0.05), 2px 2px 4px rgba(var(--shadowBlack), 0.1);
}

.primary.disabled,
.primary:disabled {
  color: #fff;
  background-color: var(--textGray);
  border-color: var(--textGray);
}

.option-btn {
  background-color: var(--gray);
  border-radius: 8px;
  border: 1px solid var(--grayLight);
  padding: 1rem;
  margin-bottom: 12px;
}

.option-btn h3 {
  color: var(--white);
}

.option-btn:hover {
  background-color: var(--white);
}

.option-btn:hover h3 {
  color: var(--black);
}

.standard-btn,
.secondary-btn {
  box-shadow: -6px -6px 14px rgba(var(--shadow), 0.7), -6px -6px 10px rgba(var(--shadow), 0.5),
    6px 6px 8px rgba(var(--shadow), 0.075), 6px 6px 10px rgba(var(--shadowBlack), 0.15);
}

.standard-btn:hover,
.secondary-btn:hover {
  box-shadow: -2px -2px 6px rgba(var(--shadow), 0.6), -2px -2px 4px rgba(var(--shadow), 0.4),
    2px 2px 2px rgba(var(--shadow), 0.05), 2px 2px 4px rgba(var(--shadowBlack), 0.1);
}

.standard-btn {
  background-color: var(--secondary);
}
