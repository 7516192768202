.loading-card {
  height: 300px;
  border-radius: 25px;
}

.loading-tile {
  height: 70px;
  border-radius: 10px;
}

.shimmer-element {
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
