.jd-attributes {
  background-color: var(--primaryLightest);
  color: var(--primary);
  margin-right: 1%;
  padding: 4px 14px;
  border-radius: 20px;
  margin-bottom: 5px;
}

.interview-active {
  color: var(--green);
  background-color: var(--lightGreen);
  border-radius: 16px;
  padding: 4px 8px;
  width: fit-content;
  height: fit-content;
}

.interview-expired {
  color: var(--red);
  background-color: var(--lightRed);
  border-radius: 16px;
  padding: 4px 8px;
  width: fit-content;
  height: fit-content;
}

.interview-in-future {
  color: var(--primary);
  background-color: var(--primaryLightest);
  border-radius: 16px;
  padding: 4px 8px;
  width: fit-content;
  height: fit-content;
}

.input-container {
  position: relative;
  min-width: 250px;
}

.inputField {
  padding-left: 40px;
  width: 100%;
  box-sizing: border-box;
}

.image {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.active {
  background-color: var(--primary) !important;
  color: white !important;
  border: none;
}

.chart-card {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  box-shadow: -6px -6px 14px rgba(var(--shadow), 0.7), -6px -6px 10px rgba(var(--shadow), 0.5),
    6px 6px 8px rgba(var(--shadow), 0.075), 6px 6px 10px rgba(var(--shadowBlack), 0.15);
}

.company-logo {
  width: auto;
  height: 40px;
}

.clickable-text {
  color: #f9c06e;
  cursor: pointer;
  font-weight: bold;
}

.empty-doughnut {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 60px solid gainsboro;
}
