.primary-input {
  padding: 12px;
  border-radius: 8px;
  border: none;
  box-shadow: inset 6px 6px 10px 0 rgba(var(--shadowBlack), 0.1),
    inset -6px -6px 10px 0 rgba(var(--shadow), 0.5);
}

.right-padding {
  padding-right: 40px;
}

.primary-input:focus {
  border: 1px solid var(--primary);
  box-shadow: none;
}

.number-input {
  padding: 12px;
  border: 1px solid #dadfe3;
  border-radius: 8px;
}

.number-input:focus {
  border: 1px solid var(--primary) !important;
  box-shadow: none;
}

.suffix-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0px, -50%);
  color: var(--textGray);
}

.prefix-icon {
  position: absolute;
  top: 20%;
  left: 4%;
  color: var(--textGray);
}
